import "../styles/footer.css";

const Footer = () => {
  return (
    <div>
      <footer className="text-center text-white">
        <div className="container">
          <section className="mt-5">
            <div className="row justify-content-center pt-5">

            <div className="col-md-3 footerContent">
              <div className="footerLogo">
                <img src="/images/logo.png" alt="logo" />
              </div>
                <p className="font-weight-bold">
                  <a href="/contact" className="text-white text-decoration-none">
                    (5th Floor) Lal Ganga Business Park,<br/>Pachpedi Naka, Raipur (C.G.)<br/>
                    Pin Code: 492013
                  </a>
                </p>
              </div>

              <div className="col-md-2 footerContent">
                <h6 className="footerHeadtext">
                  <a href="/services" className="text-white">
                    Services
                  </a>
                </h6>
                <div className="footerItem">
                  <a href="/service/analytics" className="text-white">
                    Analytics
                  </a>
                  <a href="/service/backup" className="text-white">
                    Backup
                  </a>
                  <a href="/service/datacenter" className="text-white">
                    Data Center
                  </a>
                  <a href="/service/fms" className="text-white">
                    FMS
                  </a>
                  <a href="/service/infrastructure" className="text-white">
                    Infrastructure
                  </a>
                  <a href="/service/cloud" className="text-white">
                    Google Cloud
                  </a>
                </div>
              </div>

             
              <div className="col-sm-2 footerContent">
              <h6 className="footerHeadtext">
                  <a href="#!" className="text-white">
                    More
                  </a>
                </h6>
                <div className="footerItem">
                <a href="/service/network" className="text-white">
                      Network
                    </a>
                    <a href="/service/security" className="text-white">
                      Security
                    </a>
                    <a href="/service/licensing" className="text-white">
                      Software Licensing
                    </a>
                    <a href="/service/virtualization" className="text-white">
                      Virtualization
                    </a>
                </div>
                  </div>

                  <div className="col-md-2 footerContent">
                  <h6 className="footerHeadtext">
                  <a href="#!" className="text-white">
                    Learn More
                  </a>
                </h6>
                <div className="footerItem">
                  <a href="/about" className="text-white">
                    About Company
                  </a>
                  <a href="/about" className="text-white">
                    Careers
                  </a>
                  <a href="/casestudy" className="text-white">
                    Case Studies
                  </a>
                  <a href="/contact" className="text-white">
                    Contact
                  </a>
                  <a href="/service/cloud" className="text-white">
                    Cloud Security
                  </a>
                  <a href="*" className="text-white">
                    Our Policy
                  </a>
                </div>
              </div>
             

              <div className="col-md-2 footerContent">
                <h6 className="footerHeadtext">
                  <a href="/contact" className="text-white">
                    Contact Us
                  </a>
                </h6>
                <div className="footerItem">
                  <a href="/contact" className="text-white">
                    Call Us: (+91)-7845698742
                  </a>
                  <a href="/contact" className="text-white">
                    Toll Free No : 544-544-666
                  </a>
                  <a href="/contact" className="text-white">
                    Email: jrg@jrglobalservices.in
                  </a>
                  </div>
              </div>
            </div>
          </section>

          <hr className="my-5" />

      

          <section className="text-center mb-5">
            <a href="https://www.facebook.com/" className="text-white me-4">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.twitter.com/" className="text-white me-4">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.google.com/" className="text-white me-4">
              <i className="fab fa-google"></i>
            </a>
            <a href="https://www.instagram.com/" className="text-white me-4">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/" className="text-white me-4">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.github.com/" className="text-white me-4">
              <i className="fab fa-github"></i>
            </a>
          </section>
        </div>

        <div className="text-center p-3">
          © 2020 Copyright:
          <a className="text-white" href="https://jrglobalservices.com/">
            JR Global Consultancy And Services
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
